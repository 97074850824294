import ElementUILocal from 'element-ui/lib/locale/lang/vi';

import country from './country/vi';
import state from './state/vi';

export default {
  message: {
    title: 'Quầy thu ngân',
    powerByOnerway: 'Được hỗ trợ kỹ thuật bởi <span class="ic-powerby"></span>',
    checkout: {
      timeTip: 'Vui lòng hoàn tất thanh toán trong vòng <span class="time">{time</span> phút, nếu không trang sẽ hết thời gian chờ',
      billingAddressTip: 'Khoản phí sẽ xuất hiện trên hóa đơn của bạn dưới dạng {billingAddress}',
      cardInfo: 'Thẻ thông tin',
      cardNumber: 'số thẻ',
      prepaidNumber: 'số thẻ',
      summary: 'Tóm tắt theo thứ tự',
      expiredDate: 'Thời gian hiệu lực',
      issueDate: 'ngày phát hành',
      cvv: 'CVC/CVV',
      firstName: 'tên',
      lastName: 'họ',
      holderName: 'Tên chủ thẻ',
      walletAccountName: 'Tên',
      orderno: 'ID đơn hàng',
      website: 'URL',
      amount: 'số lượng',
      pay: 'chi trả',
      payNow: 'thanh toan ngay',
      cancel: 'Hủy bỏ',
      tip: 'gợi ý',
      cancelPayTip: 'Bạn có muốn từ bỏ khoản thanh toán này không?',
      yes: 'Đúng',
      no: 'KHÔNG',
      subscription: 'đăng ký',
      schedule: 'chu kỳ thanh toán',
      endTime: 'ngày hết hạn',
      subscriptionTip: 'Sau khi xác nhận thanh toán, bạn cho phép người bán tính phí vào thẻ của bạn trong tương lai theo các điều khoản. ',
      frequencyData: {
        D: '{time}ngày/lần',
      },
      endTimeData: '{year}năm {month}tháng {day}ngày',
      billingAddress: 'Địa chỉ thanh toán/Thông tin liên hệ',
      country: 'Quốc gia',
      province: 'Bang/Tỉnh/Vùng',
      address: 'Địa chỉ',
      city: 'Thành phố',
      postalCode: 'Mã Bưu Chính',
      email: 'Thư',
      phone: 'số điện thoại',
      street: 'số đường',
      number: 'số nhà',
      identityNumber: 'Số thuế',
      intallment: 'Trả góp',
      intallmentPlan: 'Kế hoạch trả góp',
      paymentMethod: 'phương thức thanh toán',
      bankName: 'Tên ngân hàng',
      iBan: 'Tài khoản ngân hàng',
      contactName: 'Tên Liên lạc',
      birthDate: 'ngày sinh',
      paymentCompleted: 'Giao dịch hoàn tất',
      wechatPaymentTip: 'Vui lòng sử dụng WeChat để quét mã QR để thanh toán',
      paymentPending: 'chờ thanh toán',
      billingCountry: 'Đất nước thanh toán',
      billingCountryTip: 'Trước tiên, vui lòng chọn quốc gia thanh toán để chúng tôi có thể đề xuất phương thức thanh toán phù hợp cho bạn. ',
      nextStep: 'Bước tiếp theo',
      changeCountryTip: 'Sau khi chuyển đổi quốc gia, chúng tôi sẽ đề xuất cho bạn một phương thức thanh toán phù hợp. ',
      scanCode: 'Quét mã QR để thanh toán',
      txnTime: 'giờ giao dịch',
      moreInfo: 'Thêm thông tin',
      applePayNotSupport: 'Thiết bị này không hỗ trợ Apple Pay. ',
      policy: {
        prefix: 'Tôi đã đọc và đồng ý',
        suffix: '',
      },
      privacy: 'Chính sách bảo mật',
      intallmentTip: 'Số tiền trả góp có thể bao gồm lãi suất bổ sung',
      payInFull: 'Thanh toán đầy đủ',
      payIn: 'Kỳ trả góp {count}',
      blikcode: 'Mã BLIK',
      unavailable: 'Không có sẵn',
      notSupportPaymentTips: 'Phương thức thanh toán hiện không có sẵn. Vui lòng quay lại trang lựa chọn.',
      returnText: 'Quay lại lựa chọn',
      returnMerchant: 'Trở về trang thương mại',
      cvvTips: 'Mã CVC nằm ở mặt sau của thẻ, bên phải dải chữ ký trắng; luôn là 3 chữ số cuối cùng đối với Visa và Mastercard. Đối với American Express, nó là 4 chữ số ở mặt trước của thẻ.',
      holderNameTips: 'Họ và tên trên thẻ',
    },
    dlocal: {
      amount: 'Giá trị',
      expirationDate: 'Hạn chót',
      ticketNumber: 'Số vé',
      ticketImage: 'Hình ảnh vé',
      downloadTicket: 'Vé Download',
      searchForShops: 'Tìm kiếm cửa hàng',
      fullBoletoView: 'Chế độ xem Boleto đầy đủ',
      back: 'Trở lại',
      copySuccess: 'Sao chép',
      errorMsg: 'Hết thời gian chờ. Không lấy được tham số.',
      instructionsTitle: 'Hướng dẫn thanh toán',
      instructionsContentPIX: 'ID Hướng dẫn thanh toán:<br/>1. Sao chép ID giao dịch<br/>2. Sử dụng ID để xác định các khoản thanh toán PIX của bạn trong ứng dụng ngân hàng của bạn hoặc đọc mã QR trên ứng dụng của ngân hàng.',
      instructionsContentRedpagos: 'Bạn sẽ cần phải đi đến một cửa hàng redpagos để thực hiện thanh toán.<br/>Bằng cách nói số thẻ ID của bạn và làm rõ rằng bạn muốn thanh toán hóa đơn dlocal, bạn sẽ có thể thực hiện thanh toán.',
      instructionsContentOXXO: '1. Đến cửa hàng oxxo gần nhất.<br/>2. Cho biết trong ô rằng bạn muốn thực hiện thanh toán oxxopay.<br/>3. Ra lệnh số tham chiếu trên thẻ này cho thu ngân để đánh máy<br/>Trực tiếp trên màn hình bán hàng.<br/>4. Thực hiện thanh toán tương ứng bằng tiền mặt.<br/>5. Khi bạn xác nhận thanh toán, nhân viên thu ngân sẽ cung cấp cho bạn một chứng từ in. Tại đây bạn có thể xác minh rằng nó đã được thực hiện chính xác. Giữ bằng chứng thanh toán này.<br/>6. Để biết thêm thông tin về khoản thanh toán của bạn, hãy truy cập trung tâm trợ giúp của chúng tôi',
      instructionsContentSPEI: '1. Công nhận ngay lập tức.<br/>2. Sau khi nhận được chuyển khoản ngân hàng, dlocal sẽ thông báo cho việc giao sản phẩm / dịch vụ bạn đang mua.<br/>3. Nếu bạn có bất kỳ vấn đề thanh toán nào, vui lòng truy cập cổng dịch vụ khách hàng của chúng tôi.',
      instructionsContentSencillito: 'Chỉ thị thanh toán trực tiếp:<br/>Tại địa điểm kinh doanh của Sencillito, bạn có thể thanh toán bằng cách cung cấp một số tham chiếu và làm rõ rằng bạn muốn thanh toán hóa đơn DLOCAL.<br/>Hướng dẫn thanh toán trực tuyến:<br/>Để thực hiện việc thanh toán bạn phải nhập Portal Sencillito, chọn "Dlocal" trong công cụ tìm kiếm của công ty và nhập mã được chỉ ra trên voucher 134216.',
      instructionsContentServipag: 'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: 'Chi tiết ngân hàng',
      bankDetailsSPEI: 'Đăng nhập vào ngân hàng trực tuyến của bạn và chuyển sang tài khoản tiếp theo. Xin lưu ý rằng quý khách không thể thanh toán bằng tiền mặt.<br/>Ngân hàng đích: <span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>Đơn vị thụ hưởng:: <span class="detail-value">{beneficiary}</span><br/>Số tiền: <span class="detail-value">{amount} {currency}</span><br/>Khái niệm thanh toán: <span class="detail-value">{concept}</span><br/>Tham khảo: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: 'Hình thức thanh toán',
      paymentTypeServipag: 'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: 'Số tiền',
      orderTips: 'Vui lòng chuyển khoản vào tài khoản sau',
      numberTitle: 'Số tài khoản (chỉ dành cho giao dịch này)',
      copyBtnText: 'Sao chép số tài khoản',
      expireText: 'Đơn hàng hết hạn trong {time}',
      bankName: 'Tên ngân hàng',
    },
    date: {
      month: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: '10',
        11: '11',
        12: '12',
      },
    },
    result: {
      success: 'thanh toán thành công',
      failed: 'Thanh toán không thành công',
      unknow: 'Thanh toán',
      unknowTip: 'Vui lòng làm mới trạng thái đơn hàng để xem kết quả thanh toán',
      jump: 'Trở lại thương gia trong <span class="hightlight">{time}s</span>',
      reason: 'lý do',
      processing: 'Xử lý....',
      timeout: 'Trang đã hết thời gian chờ, vui lòng thực hiện lại thanh toán',
      invalid: 'Yêu cầu giao dịch không hợp lệ',
      cancelled: 'Đơn đăng ký thanh toán đã bị hủy, vui lòng không thanh toán lại',
      emptyPaymentMethod: 'Không có phương thức thanh toán nào được định cấu hình cho quốc gia này.',
      error: 'Hệ thống bất thường, vui lòng vào trang đặt hàng của trang web mua sắm để kiểm tra kết quả giao dịch. ',
    },
    validator: {
      input: 'Vui lòng nhập một {field} hợp lệ',
      intallmentCardOrCountryNotSupport: 'Loại thẻ hoặc quốc gia không hỗ trợ thanh toán trả góp!',
    },
    error: {
      timeout: 'Mạng bất thường, vui lòng thử lại sau',
      cardNumber: 'Thông tin thẻ không chính xác',
    },
    country,
    state,
  },
  ...ElementUILocal,
};
